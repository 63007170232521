import Middleware from './middleware';
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime';

// Active schemes
import { Auth0Scheme } from '~auth/runtime';
Middleware.auth = authMiddleware;
export default function (ctx, inject) {
  // Options
  var options = {
    "resetOnError": false,
    "ignoreExceptions": false,
    "scopeKey": "scope",
    "rewriteRedirects": true,
    "fullPathRedirect": false,
    "watchLoggedIn": true,
    "redirect": {
      "login": "/login",
      "logout": "/",
      "home": "/",
      "callback": "/callback"
    },
    "vuex": {
      "namespace": "auth"
    },
    "cookie": {
      "prefix": "auth-wl.",
      "options": {
        "path": "/",
        "domain": "booking.experienceaether.com"
      }
    },
    "localStorage": {
      "prefix": "auth."
    },
    "defaultStrategy": "auth0"
  };

  // Create a new Auth instance
  var $auth = new Auth(ctx, options);

  // Register strategies
  // auth0
  $auth.registerStrategy('auth0', new Auth0Scheme($auth, {
    "domain": "cavu-eu-web-live.eu.auth0.com",
    "clientId": "KJalzl8GbtIH2dMCSgrchTyKjtaNR4wg",
    "logoutRedirectUri": "https://account.booking.experienceaether.com/login/",
    "name": "auth0",
    "endpoints": {
      "authorization": "https://cavu-eu-web-live.eu.auth0.com/authorize",
      "userInfo": "https://cavu-eu-web-live.eu.auth0.com/userinfo",
      "token": "https://cavu-eu-web-live.eu.auth0.com/oauth/token",
      "logout": "https://cavu-eu-web-live.eu.auth0.com/v2/logout"
    },
    "scope": ["openid", "profile", "email"]
  }));

  // Inject it to nuxt context as $auth
  inject('auth', $auth);
  ctx.$auth = $auth;

  // Initialize auth
  return $auth.init().catch(function (error) {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return;
      }
      console.error('[ERROR] [AUTH]', error);
    }
  });
}